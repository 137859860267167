import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/base.css'
import CookieConsentVue from './plugins/CookieConsentVue.js'
import VueGtag from 'vue-gtag'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)
app.use(router)
app.use(VueLazyLoad, {})
app.use(VueGtag, {
  config: { id: 'GT-WP45SGZ' },
  router,
  enabled: false,
  params: {
    anonymize_ip: true
  },
  bootstrap: false
})
app.use(CookieConsentVue, {
  cookie: {
    name: 'cc_cookie'
  },
  guiOptions: {
    consentModal: {
      layout: 'bar',
      position: 'bottom',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^_ga/
          },
          {
            name: '_gid'
          }
        ],
        reloadPage: true
      },
      services: {
        ga: {
          label: 'Google Analytics',
          onAccept: () => {},
          onReject: () => {}
        }
      }
    },
    ads: {
      autoClear: {
        cookies: [
          {
            name: /ysc/
          },
          {
            name: 'gps'
          },
          {
            name: 'pref'
          }
        ],
        reloadPage: true
      },
      services: {
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => {},
          onReject: () => {}
        }
      }
    }
  },
  language: {
    default: 'de',
    translations: {
      de: {
        consentModal: {
          title: 'Cookie-Zustimmung verwalten',
          description: 'Um dir ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies, um Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn du diesen Technologien zustimmst, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website verarbeiten. Wenn du deine Zustimmung nicht erteilst oder zurückziehst, können bestimmte Merkmale und Funktionen beeinträchtigt werden.',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          showPreferencesBtn: 'Einstellungen',
          closeIconLabel: 'Alle ablehnen und schließen',
          footer: `
            <a href="/datenschutzerklaerung/">Datenschutz</a>
            <a href="/impressum/">Impressum</a>
          `
        },
        preferencesModal: {
          title: 'Einstellungen',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
          closeIconLabel: 'Schließen',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Deine Auswahl',
              description: 'In diesem Bereich können Sie einige Präferenzen in Bezug auf die Verarbeitung Ihrer persönlichen Daten angeben. Sie können die von Ihnen getroffenen Entscheidungen jederzeit überprüfen und ändern, indem Sie dieses Feld über den angegebenen Link erneut aufrufen. Um Ihre Zustimmung zu den unten beschriebenen Verarbeitungsaktivitäten zu verweigern, schalten Sie die Kippschalter aus oder verwenden Sie die Schaltfläche "Alle ablehnen" und bestätigen Sie, dass Sie Ihre Auswahl speichern möchten.'
            },
            {
              title: 'Funktional',
              description: 'Die technische Speicherung oder der Zugang ist unbedingt erforderlich für den rechtmäßigen Zweck, die Nutzung eines bestimmten Dienstes zu ermöglichen, der vom Teilnehmer oder Nutzer ausdrücklich gewünscht wird, oder für den alleinigen Zweck, die Übertragung einer Nachricht über ein elektronisches Kommunikationsnetz durchzuführen.',
              linkedCategory: 'necessary'
            },
            {
              title: 'Performance und Analytics',
              description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookie Tabelle',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Beschreibung'
                },
                body: [
                  {
                    name: '_ga',
                    domain: location.hostname,
                    desc: 'Der wichtigste von Google Analytics verwendete Cookie, der es dem Dienst ermöglicht, einen Besucher von einem anderen zu unterscheiden, und der 2 Jahre lang gültig ist.'
                  },
                  {
                    name: '_gid',
                    domain: location.hostname,
                    desc: 'Google Analytics: Registriert eine eindeutige ID, die verwendet wird, um statistische Daten darüber zu erstellen, wie der Besucher die Website nutzt. Dieser Cookie verfällt nach 1 Tag.'
                  }
                ]
              }
            },
            {
              title: 'Komfort und Marketing',
              description: 'Die technische Speicherung oder der Zugriff ist erforderlich, um Nutzerprofile zu erstellen, um Werbung zu versenden oder um den Nutzer auf einer Website oder über mehrere Websites hinweg zu ähnlichen Marketingzwecken zu verfolgen.',
              linkedCategory: 'ads',
              cookieTable: {
                caption: 'Cookie Tabelle',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Beschreibung'
                },
                body: [
                  {
                    name: 'gps',
                    domain: location.hostname,
                    desc: 'YouTube - Speichere Standortdaten (Dauer: Sitzung)'
                  },
                  {
                    name: 'ysc',
                    domain: location.hostname,
                    desc: 'YouTube - Interaktion speichern und verfolgen (Dauer: Sitzung)'
                  },
                  {
                    name: 'pref',
                    domain: location.hostname,
                    desc: 'YouTube - Speicher die Benutzervorlieben (Dauer: 8 Monate)'
                  }
                ]
              }
            },
            {
              title: 'Weiterführende Informationen',
              description: 'Bei weiterführenden Fragen in Bezug auf Cookies und Ihre Wahlmöglichkeiten, wenden Sie sich bitte an <a href="/Impressum/">uns</a>'
            }
          ]
        }
      }
    }
  }
})

app.mount('#app')
