<script setup>
import NewsEntry from '../components/NewsEntry.vue'
</script>
<template>
  <div>
    <div class="container-fluid">

      <div class="header">
        <h1>
          Features
          <router-link :to="{name: 'Features'}"> Berlinale </router-link>
        </h1>
      </div>
      <div class="news" v-if="news">
        <div v-for="news_item in news" :key="news_item[1].slug" class="entry">
          <NewsEntry :news="news_item[1]"/>
        </div>
        <div class="clear"></div>
      </div>
      <div v-else class="loader">Wird geladen ...</div>

      <div class="pagination" v-if="news">
        <div class="pagination-left">
          <router-link class="button" v-if="(offset - 12) > 0" :to="{name: 'FeaturesPage', params: {page: Math.max(0, Math.abs(offset - 12))}, force: true}">Vorherige Seite</router-link>
          <router-link class="button" v-else-if="(offset - 12) === 0" :to="{name: 'Features'}">Vorherige Seite</router-link>
          <button v-else disabled>Vorherige Seite</button>
        </div>
        <div class="pagination-right">
          <router-link class="button" v-if="(offset + 12) < count" :to="{name: 'FeaturesPage', params: {page: Math.abs(offset + 12)}, force: true}">Nächste Seite</router-link>
          <button v-else disabled>Nächste Seite</button>
        </div>
        <div class="clear"></div>
      </div>

    </div>
  </div>
</template>
<script>
const fetchNewsUrl = 'https://api.kino-total.net/news/?category=feature'
const limit = '12'
export default {
  data () {
    return {
      news: '',
      count: 0,
      offset: 0
    }
  },
  methods: {
    fetchData (page) {
      fetch(fetchNewsUrl + '&limit=' + limit + (page > 0 ? '&offset=' + page : ''), {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.news = data.news
            this.count = data.count
            this.offset = data.offset
            document.querySelector('head meta[name="description"]').setAttribute('content', 'Aktuelle Features und Specials zu ausgewählten Themen - Berlinale, Oscars und mehr.')
            document.querySelector('head title').textContent = 'Features - Kino-Total.net'
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/features/' + (page > 0 ? page : ''))
    }
  },
  created () {
    this.fetchData(this.$route.params.page)
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.news = null
        this.fetchData(toParams.page)
        window.scrollTo(0, 0)
      }
    )
  }
}
</script>
